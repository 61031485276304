import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*'
  })
};


@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isAdmin: boolean;
  staticCompanyKey = 'DEF56C4F-808D-4F81-993C-EF67C77A5F91';
  staticProductRelationKey = '3F258F7B-0EAB-47A0-9A1B-57B2F86736D4';
  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    debugger
    this.authHttpService.login(email, password).subscribe(res => {  
      this.isUserAdmin(res.userRole);
      const result = this.setAuthFromLocalStorage(res);
      return result;
    })
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
    
  }

  logout() {
    //localStorage.removeItem(this.authLocalStorageToken);
    localStorage.clear();
    this.currentUserValue = undefined;
    this.isAdmin = false;
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth).pipe(
      map((user: any) => {
        if (user == undefined) {
          this.logout();
        }
        const userauth = this.getAuthDataFromLocalStorage();
        this.currentUserSubject.next(userauth);
        return userauth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage_Old(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }


  private setAuthFromLocalStorage(auth: any): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): any | undefined {
    try {
      debugger;
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (this.isTokenExpired()) {
        this.logout();
        return;
      }
      return lsValue ? JSON.parse(lsValue).authToken : "";
      // const authData = JSON.parse(lsValue);
      // return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getAuthDataFromLocalStorage(): any | undefined {
    try {
      debugger;
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (this.isTokenExpired()) {
        this.logout();
        return;
      }
      return lsValue?JSON.parse(lsValue):null;
      // const authData = JSON.parse(lsValue);
      // return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private getAuthFromLocalStorage_Old(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  isTokenExpired() {
    let lsValue = localStorage.getItem(this.authLocalStorageToken);
    if (lsValue) {
      const expiry = (JSON.parse(atob(JSON.parse(lsValue).authToken.split('.')[1]))).exp;
      return expiry * 1000 < Date.now();
    } else {
      return true;
    }
  }
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }


  isUserAdmin( user: any): boolean {
    // Super Admin == 1 or Primary Admin == 2 only allows these 2 roles
    if( (user == 1) || (user == 2) ){
      return  this.isAdmin = true;
    }
    return  this.isAdmin = false;
  }



}
